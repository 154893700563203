<template v-slot:activator="{ on, attrs }">
  <div class="text-center">
    <v-dialog persistent v-model="dialog" max-width="360">
      <v-card>
        <v-card-title class="text-h5 Modla_deletHead">
          Eliminar {{ titulo }}
        </v-card-title>

        <v-card-text> Esta seguro de eliminar {{ tituloData }}? </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="grey" @click="closebtn(false)"> cerrar </v-btn>

          <v-btn color="error" text @click="Delete()" :loading="loading">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Button from "@/components/Button/Btn-Principal";
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  props: [
    "form",
    "datamodal",
    "titulomodal",
    "step_id",
    "ids",
    "dataDescription",
  ],
  components: {
    // Button,
  },
  data() {
    return {
      dialog: true,
      titulo: "",
      ramo: "",
      showAler: false,
      ste: "",
      id: "",
      tituloData: "",
      loading: false,

      dialog_input: {
        label: "Creación",
        text: "",
        disabled: false,
      },
    };
  },
  methods: {
    ...mapActions("Integrator", [
      "deleteEmpresa",
      "deleteServicio",
      "deleteHomologacion",
      "deleteFormato",
      "deleteMatriz",
    ]),

    closebtn(action, message, color, icon) {
      this.$emit("cerrarmodalDelete", action, message, color, icon);
      this.dialog = false;
    },
    async Delete() {
      this.loading = true;
      switch (this.ste) {
        case 1: {
          await this.deleteEmpresa(this.id).then((resp) => {
            if (resp.status == 200) {
              const menssage = resp.data.data.lista_mensaje[0].mensaje;
              const icon = "mdi-checkbox-marked-circle";
              const color = "teal";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = "error al eliminar ramo";
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
              Swal.fire(
                "Warning",
                "No se puede eliminar empresa, debe validar que no tenga algún servicio, homologación o formato",
                "warning"
              );
            }
          });
          break;
        }
        case 2: {
          await this.deleteServicio(this.id).then((resp) => {
            if (resp.status == 200) {
              const menssage = resp.data.data.lista_mensaje[0].mensaje;
              const icon = "mdi-checkbox-marked-circle";
              const color = "teal";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = "error al eliminar ramo";
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            }
          });
          break;
        }
        case 3: {
          await this.deleteHomologacion(this.id).then((resp) => {
            if (resp.status == 200) {
              const menssage = resp.data.data.lista_mensaje[0].mensaje;
              const icon = "mdi-checkbox-marked-circle";
              const color = "teal";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = "error al eliminar ramo";
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            }
          });
          break;
        }
        case 4: {
          await this.deleteFormato(this.id).then((resp) => {
            if (resp.status == 200) {
              const menssage = resp.data.data.lista_mensaje[0].mensaje;
              const icon = "mdi-checkbox-marked-circle";
              const color = "teal";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = "error al eliminar ramo";
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            }
          });
          break;
        }
        case 5: {
          await this.deleteMatriz(this.id).then((resp) => {
            if (resp.status == 200) {
              const menssage = resp.data.data.lista_mensaje[0].mensaje;
              const icon = "mdi-checkbox-marked-circle";
              const color = "teal";
              this.closebtn(true, menssage, color, icon);
            } else {
              const menssage = "error al eliminar ramo";
              const icon = "mdi-cloud-alert";
              const color = "error";
              this.closebtn(true, menssage, color, icon);
            }
          });
          break;
        }
        case 30: {
          let message = "",
            icon = "",
            color = "";
          await this.DeleteSegment(this.id)
            .then((resp) => {
              message = resp;
              color = "success";
              icon = "mdi-checkbox-marked-circle";
            })
            .catch((resp) => {
              message = resp;
              color = "error";
              icon = "mdi-cloud-alert";
            })
            .finally(() => {
              this.closebtn(true, message, color, icon);
            });
          break;
        }
        default:
          break;
      }
      this.loading = false;
    },
  },
  mounted() {
    this.titulo = this.titulomodal;
    this.ste = this.step_id;
    this.id = this.ids;
    this.tituloData = this.dataDescription;
  },
};
</script>

<style scoped>
@import "../../../assets/CSS/ServiceStyle.css";
#BackgroundSvgs {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

#flex {
  margin-left: -90%;
}

.container-modal {
  height: 240px;
  width: 880px;
  left: 0px;
  top: 0px;
  border-radius: 10px !important;
}

.btn-Delet-cancel {
  margin-left: 13em;
}
.Modla_deletHead {
  word-break: break-word;
}
</style>
