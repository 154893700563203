<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog">
    <v-card>
      <v-card-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <v-row multi-line>
            <v-col cols="12">
              <v-select
                v-model="form.idCompania"
                :items="empresas"
                item-text="nombre"
                item-value="id"
                label="Compañia"
                class="mt-5"
                @change="setProducts"
                :rules="[(v) => !!v || 'Debe seleccionar una compañia']"
                return-object
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="form.idProducto"
                :items="products"
                item-text="nombre"
                item-value="id"
                label="Producto"
                :loading="loadingProducts"
                @change="setDeducibles"
                :rules="[(v) => !!v || 'Debe seleccionar un producto']"
                return-object
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="form.deducible"
                :items="deducibles"
                item-text="deducible"
                item-value="id"
                label="Deducible"
                :loading="loadingDeducibles"
                :rules="[(v) => !!v || 'Debe seleccionar un deducible']"
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="form.marca"
                :items="marcas"
                item-text="nombreMarca"
                item-value="idMarca"
                label="Marca"
                @change="setModelos"
                :rules="[(v) => !!v || 'Debe seleccionar una marca']"
                return-object
              ></v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="form.modelo"
                :items="modelos"
                item-text="nombreModelo"
                item-value="codigoModelo"
                label="Modelo"
                :loading="loadingModelos"
                :rules="[(v) => !!v || 'Debe seleccionar un modelo']"
                return-object
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="form.categoria"
                name="categoria"
                label="Categoría"
                placeholder="Nueva Categoría"
                :rules="[(v) => !!v || 'Campo categoría requerido']"
              >
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="form.paquete"
                name="paquete"
                label="Paquete"
                placeholder="Nuevo paquete"
                :rules="[(v) => !!v || 'Campo paquete requerido']"
              >
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="form.codigTexto"
                name="codigoTexto"
                label="Código Texto"
                placeholder="Nueva Código Texto"
                :rules="[(v) => !!v || 'Campo código texto requerido']"
              >
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="form.codigoUso"
                name="codigoUso"
                label="Código Uso"
                placeholder="Nuevo código uso"
                :rules="[(v) => !!v || 'Campo código uso requerido']"
              >
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="form.codigoAmis"
                name="codigoAmis"
                label="Código Amis"
                placeholder="Nuevo código amis"
                :rules="[(v) => !!v || 'Campo código amis requerido']"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit">
          {{ labels.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";
import { mapActions } from "vuex";

export default {
  mixins: [FormMixin],
  name: "MatrizForm",
  props: {
    empresas: {
      type: Array,
      default: () => [],
    },
    empresa: {
      type: Object,
      default: () => {},
    },
    marcas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: true,
      defaultTitle: "Formulario de Matriz",
      form: {
        idCompania: "",
        nombreCompania: "",
        codigoCompania: "",
        idProducto: "",
        nombreProducto: "",
        deducible: "",
        marca: "",
        nombreMarca: "",
        modelo: "",
        nombreModelo: "",
        categoria: "",
        paquete: "",
        codigTexto: "",
        codigoUso: "",
        codigoAmis: "",
      },
      products: [],
      deducibles: [],
      modelos: [],
      loadingProducts: false,
      loadingDeducibles: false,
      loadingModelos: false,
    };
  },
  methods: {
    ...mapActions("Integrator", [
      "getProductos",
      "getDeducibles",
      "getModelos",
    ]),

    setup() {
      if (this.empresa) {
        this.form.idCompania = this.empresa.id;
        this.form.codigoCompania = this.empresa.codigo;
        this.form.nombreCompania = this.empresa.nombre;
        this.setProducts(this.empresa);
      }
      if (this.formData) {
        this.setProducts(this.formData.idCompania);
        this.setDeducibles(this.formData.idProducto);
        this.setModelos(this.formData.marca);
      }
    },

    async setProducts(company) {
      this.loadingProducts = true;
      let payload = {};
      if (this.formData) {
        if (company?.id) {
          payload = {
            id: company.id,
          };
        } else {
          payload = {
            id: company,
          };
        }
      } else {
        payload = {
          id: company.id,
        };
      }
      await this.getProductos(payload)
        .then((resp) => {
          this.products = resp;
        })
        .finally(() => {
          this.loadingProducts = false;
        });
    },

    async setDeducibles(product) {
      this.loadingDeducibles = true;
      let payload = {};
      if (this.formData) {
        if (product.id) {
          payload = {
            id: product.id,
          };
        } else {
          payload = {
            id: product,
          };
        }
      } else {
        payload = {
          id: product.id,
        };
      }
      await this.getDeducibles(payload)
        .then((resp) => {
          this.deducibles = resp;
        })
        .finally(() => {
          this.loadingDeducibles = false;
        });
    },

    async setModelos(marca) {
      this.loadingModelos = true;
      let payload = {};
      if (this.formData) {
        if (marca.idMarca) {
          payload = {
            id: marca.idMarca,
          };
        } else {
          payload = {
            id: marca,
          };
        }
      } else {
        payload = {
          id: marca.idMarca,
        };
      }

      await this.getModelos(payload)
        .then((resp) => {
          this.modelos = resp;
        })
        .finally(() => {
          this.loadingModelos = false;
        });
    },

    setter() {
      this.form.idCompania = this.formData.idCompania;
      this.form.nombreCompania = this.formData.nombreCompania;
      this.form.codigoCompania = this.formData.codigoCompania;
      this.form.idProducto = this.formData.idProducto;
      this.form.nombreProducto = this.formData.nombreProducto;
      this.form.deducible = this.formData.deducible;
      this.form.nombreMarca = this.formData.nombreMarca;
      this.form.marca = this.formData.marca;
      this.form.modelo = parseInt(this.formData.modelo);
      this.form.nombreModelo = this.formData.nombreModelo;
      this.form.categoria = this.formData.categoria;
      this.form.paquete = this.formData.paquete;
      this.form.codigTexto = this.formData.codigTexto;
      this.form.codigoUso = this.formData.codigoUso;
      this.form.codigoAmis = this.formData.codigoAmis;
    },
  },
};
</script>
