<template>
  <v-container class="not-extended">
    <v-data-table
      :items="matrizItems"
      :headers="headers"
      :loading="loading"
      class="elevation-1"
      :footerProps="{
        'items-per-page-text': 'mostrar:',
        'items-per-page-options': [5, 10, 25, 50],
      }"
      loadingText="Cargando... espere un momento."
      emptyText="No se han encontrado registros."
      v-on="tableOn"
    >
      <template v-slot:top>
        <div class="d-flex align-center py-3 px-6">
          <!-- BUSCADOR -->
          <!-- <v-text-field
            v-model="search"
            v-bind="bind.search"
            v-on="searchOn"
          ></v-text-field>

          <v-divider
            vertical
            class="mx-3"
            v-if="!$vuetify.breakpoint.xs"
          ></v-divider> -->

          <!-- SELECTOR DE EMPRESA -->

          <v-autocomplete
            v-model="empresa"
            :items="companiesAll"
            item-text="nombre"
            item-value="id"
            dense
            clearable
            return-object
            label="Empresa"
            hide-details
            @change="getMatrixTable"
          ></v-autocomplete>

          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-btn v-bind="bind.refresh" v-on="on.refresh" @click="getAll">
            <v-icon>{{ icons.refresh }}</v-icon></v-btn
          >
          <!-- BOTON PARA NUEVA MATRIZ -->
          <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn color="primary" text fab x-small @click="openForm(item)">
          <v-icon> {{ icons.edit }} </v-icon>
        </v-btn>
        <v-btn
          color="error"
          fab
          text
          x-small
          @click="openDelete('Matriz', 5, item._id, item.idProducto)"
        >
          <v-icon> {{ icons.delete }} </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <Modal
      v-model="dialog"
      v-bind="bind.form"
      v-on="on.form"
      :empresas="companiesAll"
      :marcas="marcas"
      :empresa="empresa"
    />

    <ModalDelete
      v-if="state_modal_delete"
      @cerrarmodalDelete="cerrarmodalDelete"
      :titulomodal="titulomodal"
      :step_id="step_id"
      :ids="ids"
      :dataDescription="dataDescription"
    />
  </v-container>
</template>
<script>
import Modal from "@/components/Modal/ModalIntegrator/Modal-Matriz";
import ModalDelete from "@/components/Modal/ModalIntegrator/Modal-deletConf";
import { mapActions, mapGetters } from "vuex";

import PaginationMixin from "@/Mixins/PaginationMixin.js";
import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "MatrizQualitasPage",
  mixins: [PageMixin, PaginationMixin],
  components: {
    Modal,
    ModalDelete,
  },
  data: () => ({
    state_modal_update: false,
    state_modal_delete: false,
    headers: [
      { text: "Compañia", value: "nombreCompania", align: "center" },
      { text: "Producto", value: "nombreProducto", align: "center" },
      { text: "Deducible", value: "deducible", align: "center" },
      { text: "Marca", value: "nombreMarca", align: "center" },
      { text: "Modelo", value: "nombreModelo", align: "center" },
      { text: "Categoria", value: "categoria", align: "center" },
      { text: "Paquete", value: "paquete", align: "center" },
      { text: "Código Texto", value: "codigTexto", align: "center" },
      { text: "Código Uso", value: "codigoUso", align: "center" },
      { text: "Código Amis", value: "codigoAmis", align: "center" },
      { text: "", value: "actions", align: "center", sortable: false },
    ],
    empresa: null,
    companiesAll: [],
    marcas: [],
    matriz: [],
    idEmpresa: "",
  }),

  created() {
    this.getAll();
    this.get_Companies_All();
    this.get_marcas();
  },

  computed: {
    ...mapGetters("Integrator", ["matrizItems"]),

    items() {
      return this.matrizItems;
    },
  },

  methods: {
    ...mapActions("Integrator", [
      "getAllMatriz",
      "getMatrizByCompany",
      "getCompaniesAll",
      "getMarcas",
      "CrearMatriz",
      "UpdateMatriz",
    ]),

    getMatrixTable() {
      this.loading = true;
      this.getMatrizByCompany({
        ...this.pagination,
        id: this.empresa?.id,
        search: this.search,
      })
        .then((resp) => {
          if (resp?.data) {
            this.setPaginationFromResponse(resp.pagination);
          } else {
            if (!this.empresa?.id) {
              this.getAll();
              this.loading = false;
            }
            this.loading = false;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.snackbar(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getAll() {
      this.loading = true;
      this.getAllMatriz({
        ...this.pagination,
        search: this.search,
      })
        .then((resp) => {
          this.setPaginationFromResponse(resp.pagination);
        })
        .catch((e) => {
          this.snackbar(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setPaginationFromResponse(pagination = null) {
      if (pagination) {
        this.pagination.page = parseInt(pagination?.page) ?? 1;
        this.pagination.pages = pagination?.paginas ?? 1;
        this.pagination.objects = pagination?.total ?? 0;
      }
    },

    async get_Companies_All() {
      await this.getCompaniesAll().then((resp) => {
        this.companiesAll = resp;
      });
    },

    async get_marcas() {
      await this.getMarcas().then((resp) => {
        this.marcas = resp;
      });
    },

    saveItem(formData) {
      let METHOD = this.CrearMatriz;
      this.loadingForm = true;
      const payload = {
        idCompania: formData.idCompania.id
          ? formData.idCompania.id
          : formData.idCompania,
        codigoCompania: formData.idCompania.codigo
          ? formData.idCompania.codigo
          : formData.codigoCompania,
        nombreCompania: formData.idCompania.nombre
          ? formData.idCompania.nombre
          : formData.nombreCompania,
        idProducto: formData.idProducto.id
          ? formData.idProducto.id
          : formData.idProducto,
        nombreProducto: formData.idProducto.nombre
          ? formData.idProducto.nombre
          : formData.nombreProducto,
        deducible: formData.deducible,
        marca: formData.marca.idMarca ? formData.marca.idMarca : formData.marca,
        nombreMarca: formData.marca.nombreMarca
          ? formData.marca.nombreMarca
          : formData.nombreMarca,
        modelo: formData.modelo.codigoModelo
          ? formData.modelo.codigoModelo.toString()
          : formData.modelo.toString(),
        nombreModelo: formData.modelo.nombreModelo
          ? formData.modelo.nombreModelo
          : formData.nombreModelo,
        categoria: formData.categoria,
        paquete: formData.paquete,
        codigTexto: formData.codigTexto,
        codigoUso: formData.codigoUso,
        codigoAmis: formData.codigoAmis,
      };
      if (this.selected) {
        METHOD = this.UpdateMatriz;
        payload.idMatriz = this.selected._id;
      }
      METHOD(payload)
        .then((response) => {
          if (this.empresa) {
            this.getMatrixTable(this.empresa?.id);
          } else {
            this.getAll();
          }
          this.closeForm();
          this.snackbar(response.info, "success");
        })
        .catch((e) => {
          this.snackbar(e.info);
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },

    openDelete(tipo, step, id, data) {
      this.titulomodal = tipo;
      this.step_id = step;
      this.ids = id;
      this.dataDescription = data;
      this.state_modal_delete = true;
    },

    cerrarmodalDelete(action, message, color, icon) {
      if (action == false) {
        this.state_modal_delete = false;
      } else {
        this.state_modal_delete = false;
        this.snackbar = true;
        this.color_message = color;
        this.text_Menssage = message;
        this.icon = icon;
        this.getMatrixTable();
      }
    },

    snackbar(text = "", color = "error") {
      this.$store.commit("setSnackbar", {
        active: true,
        text: text,
        top: true,
        right: true,
        color: color,
      });
    },
  },
};
</script>
